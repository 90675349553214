import { Context } from '@nuxt/types';
import { actionTree, getAccessorType } from 'typed-vuex';

import { AllCookies } from '@/domain/core/http/Cookie.enum';
import { CurrencyCode } from '@/infrastructure/core/store/Currency.interface';
import { RootState } from '@/infrastructure/core/store/Root.interface';
import * as address from '@/store/address';
import * as featureFlags from '@/store/featureFlags';
import * as header from '@/store/header';
import * as notifications from '@/store/notifications';
import * as pricing from '@/store/pricing';
import * as shop from '@/store/shop';
import * as ui from '@/store/ui';
import * as user from '@/store/user';
import * as wishlist from '@/store/wishlist';

export const state = (): RootState => ({});

export const actions = actionTree({ state }, {
  async nuxtServerInit(_, { $cookies, $errorMonitor, req }: Context): Promise<void> {
    try {
      const accessor: typeof accessorType = this.app.$accessor;
      const { currency: currencyCode } = $cookies.getAll<AllCookies>();

      accessor.user.setPostalCode(req?.headers);

      const dataRequests: Promise<void>[] = [
        accessor.pricing.fetchCurrencies(),
        accessor.featureFlags.getAllFeatureFlags(),
        accessor.user.fetchUser(),
      ];

      const [currenciesRequest] = await Promise.allSettled(dataRequests);

      await accessor.featureFlags.getAllFeatureFlags();

      if (currenciesRequest?.status === 'fulfilled') {
        accessor.pricing.setCurrencyCode(currencyCode as CurrencyCode);
      }
    } catch (err) {
      $errorMonitor.report(err, 'fatal');
    }
  },
});

export const accessorType = getAccessorType({
  actions,
  modules: {
    address,
    header,
    notifications,
    pricing,
    shop,
    ui,
    user,
    wishlist,
    featureFlags,
  },
});
