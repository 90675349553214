import { render, staticRenderFns } from "./ProductPriceTag.vue?vue&type=template&id=59062634"
import script from "./ProductPriceTag.vue?vue&type=script&lang=ts"
export * from "./ProductPriceTag.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicrodataOffer: require('/home/runner/work/webapp/webapp/components/atoms/microdata/MicrodataOffer.vue').default})
